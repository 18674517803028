<template>
  <div class="ticketEnPageWrap en">
    <m-layout-bar>
      <div class="ticketEnWrap">
        <div class="bannerWrap">
          <img class="banner" :src="require('../assets/image/ticket_en/banner.png')" alt />
        </div>
        <div class="titleWrap">
          <img class="banner" :src="require('../assets/image/ticket_en/title.png')" alt />
        </div>
        <div class="contentWrap">
          <!-- group 1 -->
          <div class="group">
            <span class="title">1.Park tickets (three parks)</span>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Adult
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥168
                  <div class="gap"></div>
                </div>
                <div class="c3">Passengers above 1.4m in height</div>
              </div>
            </div>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Preferential
                  ticket
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥110
                  <div class="gap"></div>
                </div>
                <div class="c3">
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                </div>
              </div>
            </div>
          </div>

          <!-- group 2 -->
          <div class="group">
            <span class="title">2.Projects in the park</span>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Tiger yo
                  (single)
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥100
                  <div class="gap"></div>
                </div>
                <div class="c3">
                  <div>1. Tourists aged 3 years and below are not allowed to ride;</div>
                  <div>2. Children whose height is less than 1.3m and do not meet the age of 8 need to be accompanied by adults with tickets</div>
                </div>
              </div>
            </div>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Preferential
                  ticket
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥110
                  <div class="gap"></div>
                </div>
                <div class="c3">
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                </div>
              </div>
            </div>
          </div>

          <!-- group 3 -->
          <div class="group">
            <span class="title">3.Project coupon</span>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Adult
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥168
                  <div class="gap"></div>
                </div>
                <div class="c3">Passengers above 1.4m in height</div>
              </div>
            </div>
            <div class="grid">
              <div class="gridTitle">
                <div class="c1">type</div>
                <div class="c2">Price</div>
                <div class="c3">Applicable customer base</div>
              </div>
              <div class="gridContent">
                <div class="c1">
                  Preferential
                  ticket
                  <div class="gap"></div>
                </div>
                <div class="c2">
                  ￥110
                  <div class="gap"></div>
                </div>
                <div class="c3">
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                  <span class="row">1.Preferential</span>
                  <span class="row">2.ticket</span>
                </div>
              </div>
            </div>
          </div>

          <div class="btn">Purchase Online Now</div>

          <p>Free ticket Description: 1. Children: height 1.2m and below; 2 old people: 80 years old and above (with valid ID card).</p>
          <p>Note: the free ticket crowd needs to be accompanied by the adult who buys the ticket. Each adult who buys the ticket can only carry one free child or one free old man.</p>
          <p>Park Ticket: including 3 parks and 1 tower (agricultural park, Dendrobium Culture Park, yo yo Park and yo yo tower)</p>
          <p>Tiger yo: one car can take 2 adults and enough single ticket tourists. The staff has the right to arrange two people to take one car according to the passenger flow of the day.</p>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.ticketEnWrap {
  > .titleWrap {
    width: 1400px;
    margin: 0 auto;
    > img {
      display: block;
    }
  }
  .contentWrap {
    width: 1400px;
    margin: 0 auto 30px;
    padding-bottom: 30px;
    text-align: center;
    // background: red;
    > .group {
      width: 1315px;
      margin: 0 auto;
      > .title {
        font-size: 30px;
        color: $font-grayDarkDD;
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
      }
      > .grid {
        border: 1px solid $main-gray;
        color: $font-grayDarkDD;
        width: 1314px;
        height: 194px;
        border-radius: 3px;
        margin-bottom: 21px;
        > .gridTitle {
          > div {
            display: inline-block;
            height: 41px;
            line-height: 41px;
            background: $main-blue;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            border-radius: 3px;
            border: 1px solid $main-gray;
            border-top: 0;
            box-sizing: border-box;
          }
          > .c1 {
            width: 194px;
            border-left: 0;
          }
          > .c2 {
            width: 194px;
          }
          > .c3 {
            width: 926px;
            border-right: 0;
          }
        }
        > .gridContent {
          > div {
            position: relative;
            display: table-cell;
            text-align: center;
            height: 155px;
            // line-height: 155px;
            vertical-align: middle;
            > .row {
              display: block;
              margin-bottom: 3px;
            }
            > .gap {
              position: absolute;
              top: 12px;
              right: 0;
              width: 1px;
              height: 130px;
              background: $main-gray;
            }
          }
          > .c1 {
            width: 194px;
            font-size: 24px;
            font-weight: bold;
          }
          > .c2 {
            width: 194px;
            font-size: 24px;
          }
          > .c3 {
            width: 926px;
            font-size: 16px;
            text-align: left;
            padding-left: 23px;
            box-sizing: border-box;
            // padding-left: 22px;
          }
        }
      }
    }

    > .group:nth-of-type(2) {
      > .grid {
        > .gridTitle {
          > div {
            background: $bg-yellow;
          }
        }
      }
    }

    > .group:nth-of-type(3) {
      > .grid {
        > .gridTitle {
          > div {
            background: $bg-green2;
          }
        }
      }
    }

    > .btn {
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      padding: 15px 340px;
      margin-top: 38px;
      margin-bottom: 31px;
      // margin: 38px auto 0;
      background: $main-blue;
      text-align: center;
      color: $main-white;
      border-radius: 50px;
      box-sizing: border-box;
      border: 1px solid $main-blue;
      transition: all 0.2s ease-in;
      &:hover {
        border: 1px solid $main-blue;
        background: $main-white;
        color: $font-grayDarkDD;
      }
    }
    p {
      text-align: left;
      line-height: 1.7;
    }
  }
}
</style>

